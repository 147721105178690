import { atom, FormikValues, useRecoilState, Yup } from "components/common";
import { phoneRegExp } from "constants/masks";
import useFunctions from "layouts/main-layout/ts/useFunctions";
import { useNavigate } from "react-router-dom";
import { useSetAuthDataState } from "states/authState";
import axiosInstance from "utils/axios";
import { showLoadingToast, updateToast } from "utils/toast";

const initialLoginValues = {
  email: "",
  password: "",
};
const initialSignupValues = {
  first_name: "",
  last_name: "",
  email: "",
  password: "",
  phone_number: "",
};

const passwordRegex =
  /^(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,16}$/;

const validationLoginSchema = Yup.object().shape({
  email: Yup.string()
    .email("Enter a valid email")
    .required("Please enter your email address"),
  password: Yup.string()
    .matches(
      passwordRegex,
      "Password must be 6-16 characters long, include at least one uppercase letter, one special character, and one digit"
    )
    .required("Please enter your password"),
});
const validationSignupSchema = Yup.object().shape({
  first_name: Yup.string().required("Please enter your first name"),
  last_name: Yup.string().required("Please enter your last name"),
  email: Yup.string()
    .email("Enter a valid email")
    .required("Please enter your email address"),
  phone_number: Yup.string()
    .matches(phoneRegExp, "Phone number is not valid")
    .required("Please enter your email address"),
  password: Yup.string()
    .matches(
      passwordRegex,
      "Password must be 6-16 characters long, include at least one uppercase letter, one special character, and one digit"
    )
    .required("Please enter your password"),
});

const tabState = atom({
  key: "auth-tab",
  default: 0,
});

export default function useAuthFunctions() {
  const setAuthData = useSetAuthDataState();
  const { loginDialogOpen, handleLoginDialogToggle } = useFunctions();
  const [tab, setTab] = useRecoilState(tabState);

  const navigate = useNavigate();

  const onLoginSubmit = async (values: FormikValues) => {
    const id = showLoadingToast("Please wait...");

    await axiosInstance
      .get("/users/login", { params: values })
      .then((response) => {
        updateToast(id, {
          render: "Login successful!",
          type: "success",
          isLoading: false,
          autoClose: 5000,
        });
        setAuthData({ token: response.data.token, isLoggedIn: true });
        handleLoginDialogToggle();
        if (loginDialogOpen.isBecomeDealer) navigate("/become-dealer");
      })
      .catch((error) => {
        const data = error.response.data;

        if (data.code === "auth/invalid-credential") {
          updateToast(id, {
            render: "Invalid Credentials",
            type: "error",
            isLoading: false,
            closeOnClick: true,
            closeButton: true,
            autoClose: 5000,
          });
        } else if (data.code === "auth/invalid-login-credentials") {
          updateToast(id, {
            render: "Invalid email or password",
            type: "error",
            isLoading: false,
            closeOnClick: true,
            closeButton: true,
            autoClose: 5000,
          });
        } else {
          updateToast(id, {
            render: "Server Error",
            type: "error",
            isLoading: false,
            closeOnClick: true,
            closeButton: true,
            autoClose: 5000,
          });
        }
      });
  };
  const onRegisterSubmit = async (values: FormikValues) => {
    const id = showLoadingToast("Please wait...");
    await axiosInstance
      .put("/users/register", values)
      .then((response) => {
        updateToast(id, {
          render: "Register successful!",
          type: "success",
          isLoading: false,
          autoClose: 5000,
        });
        setAuthData({ token: response.data.user.token, isLoggedIn: true });
        handleLoginDialogToggle();
        if (loginDialogOpen.isBecomeDealer) navigate("/become-dealer");
      })
      .catch((error) => {
        const data = error.response.data;
        if (data.code === "auth/email-already-in-use") {
          updateToast(id, {
            render:
              "User with the provided email already exists, please login or reset your password",
            type: "error",
            isLoading: false,
            closeOnClick: true,
            closeButton: true,
            autoClose: 5000,
          });
        } else {
          updateToast(id, {
            render: "Server Error",
            type: "error",
            isLoading: false,
            closeOnClick: true,
            closeButton: true,
            autoClose: 5000,
          });
        }
      });
  };

  return {
    tab,
    setTab,
    initialLoginValues,
    initialSignupValues,
    validationLoginSchema,
    validationSignupSchema,
    onLoginSubmit,
    onRegisterSubmit,
  };
}
