import { FormikValues } from "formik";
import { useNavigate } from "react-router-dom";
import { atom, useRecoilState } from "recoil";
import { useAuthDataState } from "states/authState";
import axiosInstance from "utils/axios";

const loaderState = atom({
  key: "becomeDealerLoaderState",
  default: true,
});

export default function useBecomeDealerFunctions() {
  const navigate = useNavigate();
  const [authState] = useAuthDataState();
  const [isLoading, setIsLoading] = useRecoilState(loaderState);

  const getDealerState = () => {
    setIsLoading(true);
    axiosInstance
      .get("/dealers/account", {
        headers: {
          Authorization: `Bearer ${authState.token}`,
        },
      })
      .then((res) => {
        const data = res.data;
        if (data.currentStep === 7) {
          navigate(`/become-dealer/7`, { state: data.progress });
        }
        if (data.progress === "pending") {
          navigate(`/become-dealer/${data.currentStep}`);
        }
      })
      .catch((error) => {
        const response = error.response;
        const status = response?.status;

        if (status === 404) {
          navigate("/become-dealer");
        }
      })
      .finally(() => {
        setTimeout(() => {
          setIsLoading(false);
        }, 1000);
      });
  };

  const onAgree = async () => {
    setIsLoading(true);
    await axiosInstance
      .put("/dealers/register", {
        headers: {
          Authorization: `Bearer ${authState.token}`,
        },
      })
      .then((e) => {
        getDealerState();
      });
  };

  const handleForm = async (values: FormikValues, step: number) => {
    await axiosInstance
      .post("/dealers/update", { ...values, currentStep: step })
      .then((e) => {
        getDealerState();
      });
  };

  return { isLoading, handleForm, onAgree, getDealerState };
}
