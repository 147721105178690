import { CartItem } from "constants/cart/constants";
import {
  atom,
  useRecoilState,
  useResetRecoilState,
  useSetRecoilState,
} from "recoil";
import { localStorageEffect } from "utils/localStorageUtils";

const localStorageKey = "soundlabz-cart";

export const cartState = atom<CartItem>({
  key: "cartState",
  default: { total: 0, items: [], coupon: null, discountedTotal: 0 },
  effects: [localStorageEffect(localStorageKey)],
});

export const useCartDataState = () => useRecoilState(cartState);
export const useSetCartDataState = () => useSetRecoilState(cartState);
export const useResetCartDataState = () => useResetRecoilState(cartState);
