import AuthedLayout from "layouts/authed-layout/AuthedLayout";
import MainLayout from "layouts/main-layout/MainLayout";
import { useAuthDataState } from "states/authState";

import { Navigate, useRoutes } from "react-router-dom";

import {
  HomePage,
  ContactPage,
  AboutUsPage,
  ProductsPage,
  ProductDetailsPage,
  CartPage,
  CheckoutPage,
  BecomeDealerIntroPage,
  BecomeDealerDetailsPage,
  BecomeDealerForm1Page,
  BecomeDealerForm2Page,
  BecomeDealerForm3Page,
  BecomeDealerForm4Page,
  BecomeDealerForm5Page,
  BecomeDealerForm6Page,
  BecomeDealerStatuspage,
} from "./lazyRoutes";
import BecomeDealerLayout from "layouts/become-dealer-layout";
import UniversePage from "pages/universe";

export default function Router() {
  const [user] = useAuthDataState();
  const routes = useRoutes([
    {
      element: !user.isLoggedIn ? <MainLayout /> : <AuthedLayout />,
      children: [
        { index: true, element: <HomePage /> },
        { path: "contact-us", element: <ContactPage /> },
        { path: "about-us", element: <AboutUsPage /> },
        {
          path: "products",
          children: [{ path: ":first?/:second?", element: <ProductsPage /> }],
        },
        {
          path: "product-details",
          children: [{ path: ":id?", element: <ProductDetailsPage /> }],
        },
        { path: "cart", element: <CartPage /> },
        { path: "checkout", element: <CheckoutPage /> },
      ],
    },
    user.isLoggedIn
      ? {
          element: <BecomeDealerLayout />,
          path: "become-dealer",
          children: [
            { index: true, element: <BecomeDealerIntroPage /> },
            { path: "details", element: <BecomeDealerDetailsPage /> },
            { path: "1", element: <BecomeDealerForm1Page /> },
            { path: "2", element: <BecomeDealerForm2Page /> },
            { path: "3", element: <BecomeDealerForm3Page /> },
            { path: "4", element: <BecomeDealerForm4Page /> },
            { path: "5", element: <BecomeDealerForm5Page /> },
            { path: "6", element: <BecomeDealerForm6Page /> },
            { path: "7", element: <BecomeDealerStatuspage /> },
          ],
        }
      : {},
    {
      path: "*",
      element: <Navigate to="/" />,
    },
    {
      path: "/universe",
      element: <UniversePage />,
    },
  ]);

  return routes;
}
