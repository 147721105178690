import { useMemo } from "react";

import { Drawer } from "@mui/material";

import { drawerWidth } from "config";
import useFunctions from "../ts/useFunctions";
import DrawerContent from "./drawer-content";
import DrawerHeader from "./drawer-header";

export default function MainDrawer() {
  const { open, handleDrawerToggle } = useFunctions();

  const drawerHeader = useMemo(() => <DrawerHeader />, []);
  const drawerContent = useMemo(() => <DrawerContent />, []);

  return (
    <Drawer
      open={open}
      sx={{
        "& .MuiDrawer-paper": {
          width: drawerWidth,
        },
      }}
      onClose={handleDrawerToggle}
    >
      {drawerHeader}
      {drawerContent}
    </Drawer>
  );
}
