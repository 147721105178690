import {
  Button,
  Collapse,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  MenuList,
  Paper,
} from "@mui/material";
import { ForwardedRef, forwardRef, Fragment, useEffect, useState } from "react";
import { usePathname } from "hooks/use-pathname";
import { Link } from "react-router-dom";
import { theme } from "theme";
import {
  KeyboardArrowDownRounded,
  KeyboardArrowUpRounded,
} from "@mui/icons-material";
import useFunctions from "layouts/main-layout/ts/useFunctions";

interface categories {
  id: string;
  title: string;
}

interface NavItemProps {
  item: {
    title: string;
    url?: string;
    icon?: React.ElementType;
    target?: string;
    disabled?: boolean;
    type?: string;
    link_name?: string;
    categories?: categories[];
  };
  onClick?: () => void; // Optional prop for opening the modal
}

export default function NavItem({ item, onClick }: NavItemProps) {
  const pathname = usePathname();
  const [ddOpen, setddOpen] = useState(false);
  const { handleDrawerToggle } = useFunctions();

  const itemTarget = item.target ? "_blank" : "_self";
  const drawerOpen = true;
  const isSelected = item.url === pathname;

  type AnchorRef = ForwardedRef<HTMLAnchorElement>;
  type ButtonRef = ForwardedRef<HTMLButtonElement>;
  let listItemProps = {
    component:
      item.type === "link"
        ? forwardRef<AnchorRef, any>((props, ref) => (
            <Button
              ref={ref}
              {...props}
              href={item.url}
              LinkComponent={(params) => (
                <Link {...params} to={item.url || ""} target={itemTarget} />
              )}
            />
          ))
        : forwardRef<ButtonRef, any>((props, ref) => (
            <Button
              ref={ref}
              {...props}
              to={item.url || ""}
              target={itemTarget}
              sx={{ width: "100%" }}
            />
          )),
  };

  listItemProps.component.displayName = "ListItemLink";

  const itemHandler = () => {
    if (item.type === "dropdown") {
      setddOpen(!ddOpen);
    } else if (item.type === "button") {
      if (onClick) onClick();
      // handleDrawerToggle();
    } else {
      handleDrawerToggle();
    }
  };

  const Icon = item.icon;
  const itemIcon = Icon ? (
    <Icon style={{ fontSize: drawerOpen ? "1rem" : "1.25rem" }} />
  ) : (
    false
  );

  const textColor = "#636363 !important";
  const iconSelectedColor = "#fff";

  return (
    <Fragment>
      <ListItemButton
        {...listItemProps}
        disabled={item.disabled}
        onClick={itemHandler}
        selected={isSelected || ddOpen}
        sx={{
          my: 1,
          pl: 2,
          py: 1.5,
          gap: 1,
          "&:hover": {
            "& svg": {
              fill: iconSelectedColor,
            },
            "& span": {
              color: iconSelectedColor,
            },
          },
          "&.Mui-selected.Mui-focusVisible": {
            "svg, span": {
              color: "black",
            },
          },
        }}
      >
        {itemIcon && (
          <ListItemIcon
            sx={{
              minWidth: 28,
              color: isSelected ? iconSelectedColor : textColor,
              fill: textColor,
              "& svg": {
                width: "22px",
                height: "22px",
              },
            }}
          >
            {itemIcon}
          </ListItemIcon>
        )}

        <ListItemText
          sx={{
            fontSize: "17px",
          }}
          primary={item.title}
        />
        {item.type === "dropdown" && (
          <ListItemIcon
            sx={{
              minWidth: 28,
              color: ddOpen ? iconSelectedColor : textColor,
              fill: textColor,
              "& svg": {
                width: "22px",
                height: "22px",
              },
            }}
          >
            <KeyboardArrowDownRounded
              sx={{
                transition: "all 0.3s",
                transform: ddOpen ? "rotate(180deg)" : "rotate(0deg)",
              }}
            />
          </ListItemIcon>
        )}
      </ListItemButton>
      {item.type === "dropdown" && item.categories && (
        <Collapse in={ddOpen} timeout="auto" unmountOnExit>
          <Paper>
            <MenuList sx={{ p: 1 }}>
              {item.categories.map((link) => (
                <ListItemButton
                  key={link.id}
                  component={Link}
                  to={`/products/${item.link_name}/${link.id}`}
                  onClick={handleDrawerToggle}
                  sx={{
                    pl: 4,
                    py: 1.5,

                    "&:hover": {
                      "& span": {
                        color: iconSelectedColor,
                      },
                    },
                  }}
                >
                  <ListItemText
                    sx={{
                      fontSize: "17px",
                    }}
                    primary={link.title}
                  />
                </ListItemButton>
              ))}
            </MenuList>
          </Paper>
        </Collapse>
      )}
    </Fragment>
  );
}
