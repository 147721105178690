import {
  PaletteColor,
  PaletteOptions,
  ThemeOptions,
  createTheme,
} from "@mui/material/styles";
// import type {} from '@mui/x-date-pickers/themeAugmentation';
// import '@mui/x-date-pickers/themeAugmentation';
import type {} from "@mui/lab/themeAugmentation";
import "@mui/lab/themeAugmentation";

const shape = {
  borderRadius: 10,
};
const palette: PaletteOptions = {
  mode: "light",
  primary: {
    main: "#00E852",
  },
  secondary: {
    main: "#f50057",
  },
  background: {
    default: "#fff",
    // paper: "#000",
  },
  divider: "#787878",
  text: {
    primary: "#fff",
    secondary: "#000",
  },
};
const themeOptions: ThemeOptions = {
  palette: palette,
  direction: "ltr",
  shape: shape,
  typography: {
    fontFamily: "Circular Std",
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        root: {
          background: `#fff`,
          border: "none",
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          borderRadius: shape.borderRadius,
          [`@media (max-width:600px)`]: {
            padding: "10px 14px",
            marginTop: "6px",
            marginBottom: "6px",
          },
          "&:hover": {
            backgroundColor: "rgb(0, 162, 57)",
          },
          "&.Mui-selected": {
            "& .MuiTypography-root": {
              color: "white",
            },
            backgroundColor: (palette.primary as PaletteColor).main,
            "&:hover": {
              backgroundColor: (palette.primary as PaletteColor).main,
            },
          },
        },
      },
    },
    MuiListItemText: {
      styleOverrides: {
        root: {
          margin: 0,
          "& .MuiTypography-root": {
            color: "#636363",
            [`@media (max-width:600px)`]: {
              fontSize: "14px",
            },
          },
        },
      },
    },

    MuiTextField: {
      variants: [
        {
          props: { variant: "outlined", margin: "none" },
          style: {
            "& .MuiOutlinedInput-root": {
              borderRadius: "100px",
              backgroundColor: "#2B2B2B",
            },
            "& .MuiFormLabel-root": {
              color: "white",
              '&[data-shrink="false"]': {
                transform: "translate(16px, 12px) scale(1) !important",
              },
            },
            "& input, .MuiSelect-select": {
              padding: "12px 16px",
            },

            "& .MuiInputBase-adornedEnd": {
              paddingRight: 4,
            },
            "& input:-webkit-autofill": {
              // borderRadius: "100px",
            },
          },
        },
        {
          props: { variant: "outlined", margin: "normal" },
          style: {
            "& .MuiOutlinedInput-root": {
              backgroundColor: "#2B2B2B",
            },
            "& .MuiFormLabel-root": {
              color: "white",
              '&[data-shrink="false"]': {
                transform: "translate(16px, 12px) scale(1) !important",
              },
            },
            "& input, .MuiSelect-select": {
              padding: "12px 16px",
            },
            "& .MuiInputBase-adornedEnd": {
              paddingRight: 4,
            },
            "& input:-webkit-autofill": {
              "-webkit-box-shadow": "0 0 0 30px #2B2B2B inset !important",
              "-webkit-text-fill-color": "#ffffff !important",
              "-webkit-background-clip": "text",
            },
          },
        },
        {
          props: { variant: "filled" },
          style: {
            "& .Mui-disabled": {
              cursor: "no-drop",
            },
            "& .Mui-disabled.MuiInputBase-root": {
              backgroundColor: "#000",
            },
            "& .MuiInputLabel-root.Mui-disabled": {
              // color: "white !important",
            },
            "& .MuiInputBase-root": {
              borderRadius: shape.borderRadius,
              color: "#000",
              backgroundColor: "#D9D9D9",
              "& .MuiInputBase-input:-webkit-autofill": {
                borderRadius: shape.borderRadius,
              },
              "&::before, ::after": {
                visibility: "hidden",
              },
            },
            "& .MuiFormLabel-root[data-shrink='false']": {
              color: "#898989",
            },
          },
        },
      ],
      styleOverrides: {
        root: {
          "& fieldset": {
            border: "none",
          },
          "& svg": {
            color: "white",
          },
          "& .MuiIconButton-root": {
            backgroundColor: (palette.primary as PaletteColor).main,
            "&:hover": {
              backgroundColor: "white",
              "& svg": {
                color: (palette.primary as PaletteColor).main,
              },
            },
          },
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        body1: {
          color: "#CFCFCF",
        },
        subtitle1: {
          color: "#CFCFCF",
        },
        subtitle2: {
          fontSize: "16px",
          color: "#636363",
        },
        body2: {
          fontSize: "16px",
          color: "#00E852",
        },
      },
    },
    MuiButton: {
      variants: [
        {
          props: { variant: "contained" },
          style: {
            color: "white",
            boxShadow: "none",
          },
        },
        {
          props: { variant: "text" },
          style: {
            color: "black",
          },
        },
        {
          props: { variant: "text", color: "primary" },
          style: {
            color: (palette.primary as PaletteColor).main,
          },
        },
      ],
      styleOverrides: {
        root: {
          borderRadius: "5px",
          textTransform: "capitalize",
          // [`@media (max-width:600px)`]: {
          //   fontSize: "12px",
          //   lineHeight: "17px",
          //   padding: "5px 12px",
          // },
          "&.MuiLoadingButton-loading svg": {
            color: (palette.primary as PaletteColor).main,
          },
          "&:hover": {
            boxShadow: "none !important",
          },
        },
      },
      defaultProps: {
        variant: "contained",
      },
    },
    MuiLoadingButton: {
      defaultProps: {
        variant: "contained",
      },
    },
    MuiIconButton: {
      variants: [
        {
          props: { color: "default" },
          style: {
            color: "white",
            backgroundColor: (palette.primary as PaletteColor).main,
            ":hover": {
              backgroundColor: "#00a239",
            },
          },
        },
        {
          props: { color: "primary" },
          style: {
            color: "white",
            backgroundColor: (palette.primary as PaletteColor).main,
            borderRadius: "5px",
            ":hover": {
              backgroundColor: "#00a239",
            },
          },
        },
        {
          props: { color: "secondary" },
          style: {
            color: "white",
            "&:hover": {
              color: "#2B2B2B !important",
              backgroundColor: "white !important",
            },
          },
        },
      ],
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          padding: 0,
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          padding: 24,
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          padding: 0,
          paddingTop: 24,
        },
      },
    },
    // MuiDialog: {
    //   styleOverrides: {
    //     paper: {
    //       padding: 24,
    //     },
    //   },
    // },
    MuiSkeleton: {
      defaultProps: {
        variant: "rounded",
      },
      variants: [
        { props: { variant: "rounded" }, style: { borderRadius: "5px" } },
      ],
      styleOverrides: {
        root: {
          backgroundColor: "#0000001f",
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        paper: {
          backgroundColor: (palette.primary as PaletteColor).main,
          marginTop: "8px",
        },
      },
    },
    MuiMenuItem: {
      variants: [
        {
          props: { color: "primary" },
          style: {
            borderRadius: shape.borderRadius,

            ":hover": {
              backgroundColor: (palette.primary as PaletteColor).main,
            },
          },
        },
      ],
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          ".MuiOutlinedInput-root": {
            padding: "0 !important",
            " .MuiAutocomplete-input": {
              padding: "12px 16px",
            },
          },
          ".MuiIconButton-root": {
            backgroundColor: "transparent !important",
            svg: {
              color: (palette.primary as PaletteColor).main,
            },
          },
        },
        paper: {
          backgroundColor: "#2b2b2b",
          ".MuiAutocomplete-loading": {
            color: "white",
          },
        },
      },
    },
    MuiCheckbox: {
      variants: [
        {
          props: { color: "primary" },
          style: {
            "& .MuiSvgIcon-root[data-testid='CheckBoxOutlineBlankIcon']": {
              fill: "white",
            },
          },
        },
      ],
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          color: "white",
          "&.Mui-checked": {
            color: (palette.primary as PaletteColor).main,
          },
        },
      },
    },
  },
};

export const theme = createTheme(themeOptions);
