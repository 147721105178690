import { Coupon, Product } from "constants/cart/constants";
import { FormikValues } from "formik";
import { useRecoilState } from "recoil";
import { useCartDataState, useResetCartDataState } from "states/cartState";
import { showErrorToast, showInfoToast, showSuccessToast } from "utils/toast";
import axiosInstance from "utils/axios";

export default function useCartFunctions() {
  const [cart, setCart] = useCartDataState();
  const resetCart = useResetCartDataState();

  // Helper function to calculate total and discounted total with coupon
  const calculateTotal = (
    items: Product[],
    coupon: Coupon | null
  ): { total: number; discountedTotal: number; coupon: Coupon | null } => {
    let total = items.reduce((sum, item) => sum + item.subtotal, 0);
    let discountedTotal = total;

    if (coupon && total < coupon.minimumOrder) {
      showErrorToast(
        "Coupon removed: Total amount is less than the minimum order required for the coupon."
      );
      return { total, discountedTotal, coupon: null };
    }
    if (coupon) {
      let subtractedAmount = 0;
      if (coupon.discountType === "price") {
        subtractedAmount = coupon.discountValue;
      } else if (coupon.discountType === "percentage") {
        subtractedAmount = (total * coupon.discountValue) / 100;
      }

      subtractedAmount = Math.min(subtractedAmount, total); // Cap at total
      discountedTotal -= subtractedAmount;

      // Clone the coupon before modifying
      coupon = { ...coupon, subtractedAmount }; // Create a new object with the updated value
    }

    return { total, discountedTotal, coupon };
  };

  // Add item to cart
  const addToCart = (item: Product) => {
    setCart((prevCart) => {
      const existingItem = prevCart.items.find(
        (cartItem) => cartItem.id === item.id
      );

      let updatedItems: Product[] = [];
      if (existingItem) {
        updatedItems = prevCart.items.map((cartItem) =>
          cartItem.id === item.id
            ? {
                ...cartItem,
                quantity: cartItem.quantity + item.quantity,
                subtotal:
                  cartItem.price * (cartItem.quantity + (item.quantity || 1)),
              }
            : cartItem
        );
      } else {
        updatedItems = [
          ...prevCart.items,
          {
            ...item,
            quantity: item.quantity || 1,
            subtotal: item.price * (item.quantity || 1),
          },
        ];
        showSuccessToast(`${item.title} added to cart successfully!`);
      }

      const { total, discountedTotal, coupon } = calculateTotal(
        updatedItems,
        prevCart.coupon
      );
      return {
        ...prevCart,
        items: updatedItems,
        total,
        discountedTotal,
        coupon,
      };
    });
  };

  // Remove item from cart
  const removeFromCart = (id: number) => {
    setCart((prevCart) => {
      const updatedItems = prevCart.items
        .map((cartItem) => {
          if (cartItem.quantity == 1)
            showInfoToast(`${cartItem.title} removed from cart`);
          return cartItem.id === id
            ? {
                ...cartItem,
                quantity: cartItem.quantity > 1 ? cartItem.quantity - 1 : 0,
                subtotal:
                  cartItem.quantity > 1
                    ? cartItem.subtotal - cartItem.price
                    : 0,
              }
            : cartItem;
        })
        .filter((cartItem) => cartItem.quantity > 0);

      const { total, discountedTotal, coupon } = calculateTotal(
        updatedItems,
        prevCart.coupon
      );
      return {
        ...prevCart,
        items: updatedItems,
        total,
        discountedTotal,
        coupon,
      };
    });
    // showInfoToast(`Item Removed`);
  };

  // Apply promo code
  const applyPromoCode = async (values: FormikValues) => {
    const code = values.code;
    try {
      const response = await axiosInstance.post("/promos/verify", {
        code,
        value: cart.total,
      });
      const result = response.data;
      if (result.valid) {
        const coupon: Coupon = {
          code,
          discountType: result.discountType,
          discountValue: result.discountValue,
          minimumOrder: result.minimumOrder,
          subtractedAmount: 0,
        };

        const {
          total,
          discountedTotal,
          coupon: updatedCoupon,
        } = calculateTotal(cart.items, coupon);

        setCart((prevCart) => ({
          ...prevCart,
          total,
          discountedTotal,
          coupon: updatedCoupon,
        }));

        showSuccessToast(result.message || "Promo code applied successfully!");
      } else {
        showErrorToast(result.message || "Invalid promo code.");
      }
    } catch (error: any) {
      showErrorToast(
        error.response?.data?.error || "Invalid or expired promo code."
      );
    }
  };

  const clearCart = () => resetCart();

  const removeCoupon = () => {
    setCart((prevCart) => {
      const { total, discountedTotal } = calculateTotal(prevCart.items, null);
      return { ...prevCart, total, discountedTotal, coupon: null };
    });
  };

  return {
    cart,
    addToCart,
    removeFromCart,
    clearCart,
    applyPromoCode,
    removeCoupon,
  };
}
