import {
  HomeRounded as MenuItem0,
  TungstenRounded as MenuItem1,
  ContactMailRounded as MenuItem6,
  LoginRounded as MenuItem8,
} from "@mui/icons-material";
import { SetterOrUpdater } from "recoil";
const icons = {
  MenuItem0,
  MenuItem1,
  MenuItem6,
  MenuItem8,
};
export interface DropDownProps {
  open: boolean;
  categories: any[];
  index: number;
  link_name: string;
  label: string;
}

export interface NavbarOptionsProps {
  label: string;
  variant: "contained" | "text" | "outlined";
  categories?: any[];
  href?: string;
  type: "modal" | "link" | "node";
  link_name?: "deaf-bonce" | "sundown-audio" | "dd-audio" | "xs-power" | "";
}

export interface HeaderDesktopLinksItemProps {
  item: NavbarOptionsProps;
  index: number;
  handleDropDownToggle: (data: NavbarOptionsProps, index: number) => void;
  setDropDown: SetterOrUpdater<DropDownProps>;
}

export const navbarOptions: NavbarOptionsProps[] = [
  // {
  //   label: "Bulb Finder",
  //   variant: "contained",
  //   type: "modal",
  // },
  {
    label: "Deaf Bonce",
    variant: "text",
    type: "node",
    link_name: "deaf-bonce",
    categories: [
      {
        id: "amplifiers",
        title: "Deafbonce amplifiers",
      },

      {
        id: "speakers",
        title: "Deafbonce speakers",
      },
      {
        id: "subwoofers",
        title: "Deafbonce subwoofers",
      },

      {
        id: "cables",
        title: "Deafbonce cables",
      },

      {
        id: "promos",
        title: "Deafbonce promos",
      },
      {
        id: "accessories",
        title: "Deafbonce accessories",
      },

      {
        id: "recones",
        title: "Deafbonce recones",
      },
    ],
  },

  {
    label: "DD Audio",
    variant: "text",
    type: "node",
    link_name: "dd-audio",
    categories: [
      {
        id: "amplifiers",
        title: "DD Audio amplifiers",
      },

      {
        id: "speakers",
        title: "DD Audio speakers",
      },
      {
        id: "subwoofers",
        title: "DD Audio subwoofers",
      },

      {
        id: "cables",
        title: "DD Audio cables",
      },
      {
        id: "accessories",
        title: "DD Audio accessories",
      },
    ],
  },

  {
    label: "Sundown Audio",
    variant: "contained",
    type: "node",
    link_name: "sundown-audio",
    categories: [
      {
        id: "amplifiers",
        title: "Sundown Audio amplifiers",
      },

      {
        id: "speakers",
        title: "Sundown Audio speakers",
      },
      {
        id: "subwoofers",
        title: "Sundown Audio subwoofers",
      },

      {
        id: "cables",
        title: "Sundown Audio cables",
      },

      {
        id: "accessories",
        title: "Sundown Audio accessories",
      },
    ],
  },
  {
    label: "XS Power Batteries",
    variant: "text",
    type: "node",
    link_name: "xs-power",
    categories: [
      {
        id: "agm-batteries",
        title: "AGM batteries",
      },
      {
        id: "racing-batteries",
        title: "Racing batteries",
      },
      {
        id: "powersports-batteries",
        title: "Powersports batteries",
      },
      {
        id: "vintage-batteries",
        title: "Vintage batteries",
      },
      {
        id: "xp-series-batteries",
        title: "XP Series batteries",
      },

      {
        id: "xs-flex-cable",
        title: "FLEX CABLE",
      },
      {
        id: "lithium-intellichargers",
        title: "Lithium IntelliCHARGERS",
      },
      {
        id: "agm-intellichargers",
        title: "AGM IntelliCHARGERS",
      },
      {
        id: "combo-kits",
        title: "Combo Kits",
      },
      {
        id: "superbanks",
        title: "SUPERBANKS",
      },
      {
        id: "powerbanks",
        title: "POWERBANKS",
      },
      {
        id: "isolator-kits",
        title: "Isolator Kits",
      },
      {
        id: "wiring-kits",
        title: "Wiring Kits",
      },
      {
        id: "fuses",
        title: "Fuses",
      },
      {
        id: "adaptors",
        title: "TERMINAL ADAPTORS",
      },
      {
        id: "voltage-control-modules",
        title: "Voltage Control Modules",
      },
      {
        id: "hold-downs",
        title: "Hold Downs",
      },
    ],
  },
  {
    label: "Nemesis Audio",
    variant: "text",
    type: "node",
    categories: [
      {
        id: "midrange_loudspeaker",
        title: "Midrange Loudspeaker",
      },
      {
        id: "accessories",
        title: "Accessories",
      },
      {
        id: "amplifier",
        title: "Amplifier",
      },
      {
        id: "battery",
        title: "Battery",
      },
      {
        id: "speaker",
        title: "Speaker",
      },
      {
        id: "subwoofer",
        title: "Subwoofer",
      },
      {
        id: "tweeter",
        title: "Tweeter",
      },
    ],
  },
  {
    label: "Contact",
    variant: "text",
    href: "/contact-us",
    type: "link",
  },
];
export const drawerOptions = [
  {
    id: 0,
    title: "Home",
    url: "/",
    icon: icons.MenuItem0,
    type: "link",
  },
  // {
  //   id: 1,
  //   title: "Bulb Finder",
  //   icon: icons.MenuItem1,
  //   type: "button",
  // },
  {
    id: 2,
    title: "Deaf Bonce",
    link_name: "deaf-bonce",
    type: "dropdown",
    categories: [
      {
        id: "amplifiers",
        title: "Deafbonce amplifiers",
      },

      {
        id: "speakers",
        title: "Deafbonce speakers",
      },
      {
        id: "subwoofers",
        title: "Deafbonce subwoofers",
      },

      {
        id: "cables",
        title: "Deafbonce cables",
      },

      {
        id: "promos",
        title: "Deafbonce promos",
      },
      {
        id: "accessories",
        title: "Deafbonce accessories",
      },

      {
        id: "recones",
        title: "Deafbonce recones",
      },
    ],
  },
  {
    id: 3,
    title: "DD Audio",
    link_name: "dd-audio",
    type: "dropdown",
    categories: [
      {
        id: "amplifiers",
        title: "DD Audio amplifiers",
      },

      {
        id: "speakers",
        title: "DD Audio speakers",
      },
      {
        id: "subwoofers",
        title: "DD Audio subwoofers",
      },

      {
        id: "cables",
        title: "DD Audio cables",
      },
      {
        id: "accessories",
        title: "DD Audio accessories",
      },
    ],
  },
  {
    id: 4,
    title: "Sundown Audio",
    type: "dropdown",
    link_name: "sundown-audio",
    categories: [
      {
        id: "amplifiers",
        title: "Sundown Audio amplifiers",
      },

      {
        id: "speakers",
        title: "Sundown Audio speakers",
      },
      {
        id: "subwoofers",
        title: "Sundown Audio subwoofers",
      },

      {
        id: "cables",
        title: "Sundown Audio cables",
      },

      {
        id: "accessories",
        title: "Sundown Audio accessories",
      },
    ],
  },
  {
    id: 5,
    title: "XS Power",
    type: "dropdown",
    link_name: "xs-power",
    categories: [
      {
        id: "agm-batteries",
        title: "XS AGM batteries",
      },
      {
        id: "racing-batteries",
        title: "XS Racing batteries",
      },
      {
        id: "powersports-batteries",
        title: "XS Powersports batteries",
      },
      {
        id: "vintage-batteries",
        title: "XS Vintage batteries",
      },
      {
        id: "xp-series-batteries",
        title: "XS XP Series batteries",
      },

      {
        id: "xs-flex-cable",
        title: "XS FLEX CABLE",
      },
      {
        id: "lithium-intellichargers",
        title: "XS Lithium IntelliCHARGERS",
      },
      {
        id: "agm-intellichargers",
        title: "XS AGM IntelliCHARGERS",
      },
      {
        id: "combo-kits",
        title: "XS Combo Kits",
      },
      {
        id: "superbanks",
        title: "XS SUPERBANKS",
      },
      {
        id: "powerbanks",
        title: "XS POWERBANKS",
      },
      {
        id: "isolator-kits",
        title: "XS Isolator Kits",
      },
      {
        id: "wiring-kits",
        title: "XS Wiring Kits",
      },
      {
        id: "fuses",
        title: "XS Fuses",
      },

      {
        id: "adaptors",
        title: "XS TERMINAL ADAPTORS",
      },
      {
        id: "voltage-control-modules",
        title: "XS Voltage Control Modules",
      },
      {
        id: "hold-downs",
        title: "XS Hold Downs",
      },
    ],
  },
  {
    id: 6,
    title: "Nemesis Audio",
    type: "dropdown",
    categories: [
      {
        id: "midrange_loudspeaker",
        title: "Midrange Loudspeaker",
      },
      {
        id: "accessories",
        title: "Accessories",
      },
      {
        id: "amplifier",
        title: "Amplifier",
      },
      {
        id: "battery",
        title: "Battery",
      },
      {
        id: "speaker",
        title: "Speaker",
      },
      {
        id: "subwoofer",
        title: "Subwoofer",
      },
      {
        id: "tweeter",
        title: "Tweeter",
      },
    ],
  },
  {
    id: 7,
    title: "Contact",
    url: "/contact-us",
    type: "link",
  },
];
