import { Box } from "@mui/material";
import NavItem from "./NavItem";
import {
  HomeRounded as MenuItem0,
  TungstenRounded as MenuItem1,
  ContactMailRounded as MenuItem6,
  LoginRounded as MenuItem8,
} from "@mui/icons-material";
import { useState } from "react";
import BulbFinderModal from "components/bulb-finder-modal";
import useFunctions from "layouts/main-layout/ts/useFunctions";
import { link } from "fs";
import { drawerOptions } from "layouts/main-layout/ts/constants";

const icons = {
  MenuItem0,
  MenuItem1,
  MenuItem6,
  MenuItem8,
};

const Navigation = () => {
  const { handleLoginDialogToggle } = useFunctions();
  const [bulbFinderOpen, setBulbFinderOpen] = useState(false);
  const handleBulbFinderOpen = () => setBulbFinderOpen(true);
  const handleBulbFinderClose = () => setBulbFinderOpen(false);

  const navLinks = drawerOptions.map((item) => (
    <NavItem key={item.id} item={item} onClick={handleBulbFinderOpen} />
  ));

  return (
    <Box sx={{ p: 2, height: "calc(100% - 100px)", overflowY: "auto" }}>
      {navLinks}
      <Box
        sx={{
          position: "absolute",
          bottom: 12,
          width: "calc(100% - 32px)",
        }}
      >
        <NavItem
          item={{
            title: "Login",
            icon: icons.MenuItem8,
            type: "button",
          }}
          onClick={handleLoginDialogToggle}
        />
      </Box>
      <BulbFinderModal open={bulbFinderOpen} onClose={handleBulbFinderClose} />
    </Box>
  );
};

export default Navigation;
