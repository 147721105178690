import { ArrowDropDownRounded } from "@mui/icons-material";
import BulbFinderModal from "components/bulb-finder-modal";
import { CustomLinkButton } from "components/common";
import { HeaderDesktopLinksItemProps } from "layouts/main-layout/ts/constants";
import { Fragment, useState } from "react";

export default function HeaderDesktopLinksItem(
  props: HeaderDesktopLinksItemProps
) {
  const { item, index, handleDropDownToggle, setDropDown } = props;
  const [bulbFinderOpen, setBulbFinderOpen] = useState(false);

  const handleBulbFinderOpen = () => setBulbFinderOpen(true);
  const handleBulbFinderClose = () => setBulbFinderOpen(false);

  return (
    <Fragment>
      <CustomLinkButton
        variant={item.variant}
        onClick={() => {
          if (item.type === "modal" && item.label === "Bulb Finder") {
            handleBulbFinderOpen();
          } else if (item.type === "node") {
            handleDropDownToggle(item, index);
          }
        }}
        onMouseOver={() => {
          if (item.type === "node") {
            setDropDown({
              open: true,
              index: index,
              label: item.label,
              link_name: item.link_name || "",
              categories: item.categories || [],
            });
          }
        }}
        href={item.type === "link" ? item.href : ""}
        endIcon={item.type === "node" ? <ArrowDropDownRounded /> : null}
      >
        {item.label}
      </CustomLinkButton>
      {item.label === "Bulb Finder" && (
        <BulbFinderModal
          open={bulbFinderOpen}
          onClose={handleBulbFinderClose}
        />
      )}
    </Fragment>
  );
}
