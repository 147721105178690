import { useDashboardLoaderState } from "states/dashboardLoaderState";
import { useDrawerState, useSetDrawerState } from "states/drawerState";
import { useEffect, useState } from "react";
import { atom, useRecoilState } from "components/common";
import axiosInstance from "utils/axios";
import { useAuthDataState, useResetAuthDataState } from "states/authState";
import useCartFunctions from "hooks/use-cart";
import { useDealerSwitchState } from "states/dealerSwitchState";
import { useNavigate } from "react-router-dom";
// import { atom, useRecoilState } from "recoil";

const headerHeightState = atom({
  key: "HeaderHeightState",
  default: 0,
});
const userState = atom({
  key: "UserState",
  default: {
    created_at: "",
    email: "",
    first_name: "",
    id: 0,
    isDealer: false,
    is_super_user: false,
    last_name: "",
    password: "",
    phone_num: "",
    dealerDetails: {
      progress: "",
    },
  },
});
const userApiState = atom({
  key: "user-api-state",
  default: false,
});

export default function useFunctions() {
  const navigate = useNavigate();
  const [open] = useDrawerState();
  const setOpen = useSetDrawerState();
  const [loader] = useDashboardLoaderState();
  const [headerHeight, setHeaderHeight] = useRecoilState(headerHeightState);
  const [userApiLoader, setUserApiLoader] = useRecoilState(userApiState);
  const [user, setUser] = useRecoilState(userState);
  const [dealerSwitch, setDealerSwitch] = useDealerSwitchState();
  const [authState] = useAuthDataState();
  const { cart, addToCart, removeFromCart, clearCart } = useCartFunctions();
  const resetAuthState = useResetAuthDataState();
  const handleAuthReset = () => {
    resetAuthState();
    navigate("/");
  };

  const handleDrawerToggle = () => {
    setOpen(!open);
  };
  const handleDealerSwitchToggle = () => {
    setDealerSwitch(!dealerSwitch);
    clearCart();
    window.location.reload();
  };
  const handleBecomeDealer = () => {
    navigate("/become-dealer");
  };
  const getUser = () => {
    setUserApiLoader(true);
    axiosInstance
      .get("/users/info", {
        headers: {
          Authorization: `Bearer ${authState.token}`,
        },
      })
      .then((response) => {
        setUser(response.data);
      })
      .catch((error) => {
        setDealerSwitch(false);
      })
      .finally(() => setUserApiLoader(false));
  };

  return {
    loader,
    open,
    dealerSwitch,
    headerHeight,
    user,
    cart,
    userApiLoader,
    setOpen,
    handleDrawerToggle,
    handleDealerSwitchToggle,
    handleBecomeDealer,
    handleAuthReset,
    getUser,
    setHeaderHeight,
    addToCart,
    removeFromCart,
    clearCart,
  };
}
