import { CircularProgress } from "@mui/material";
import {
  AppBar,
  Box,
  Collapse,
  CustomIconButton,
  CustomInput,
  CustomLink,
  HideOnScroll,
  LocalMallRounded,
  MenuOpenRounded,
  MenuRounded,
  MuiBadge,
  MuiLoadingButton,
  ProjectLogo,
  SearchRounded,
  Toolbar,
  useMediaQuery,
} from "components/common";
import { Fragment, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { theme } from "theme";
import useFunctions from "../ts/useFunctions";

import HeaderSearch from "components/header-search";
import HeaderDesktopLinks from "components/layouts/header-desktop-links";
import HeaderAccountMenu from "./header-account-menu";

export default function Header() {
  const navigate = useNavigate();
  const {
    open,
    dealerSwitch,
    user,
    userApiLoader,
    cart,
    handleDrawerToggle,
    handleBecomeDealer,
    getUser,
    handleDealerSwitchToggle,
    setHeaderHeight,
  } = useFunctions();
  const matchDownMD = useMediaQuery(theme.breakpoints.down("md"));
  const [showSearch, setShowSearch] = useState(false);
  const [showMobileSearch, setShowMobileSearch] = useState(false);

  const handleMobileSearchToggle = () => {
    setShowMobileSearch((prev) => !prev);
  };
  const handleSearchToggle = () => {
    setShowSearch((prev) => !prev);
  };

  const headerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    getUser();
  }, []);

  useEffect(() => {
    if (headerRef.current) {
      setHeaderHeight(headerRef.current.clientHeight);
    }
  }, [setHeaderHeight]);

  return (
    <HideOnScroll>
      <AppBar
        ref={headerRef}
        variant="outlined"
        sx={{
          zIndex: 10,
          width: "100%",
        }}
        elevation={0}
      >
        <Toolbar
          sx={{
            height: matchDownMD ? "80px" : "100px",
            justifyContent: "center",
            backgroundColor: "black",
            minHeight: "0 !important",
          }}
        >
          <Box
            width="100%"
            maxWidth={1200}
            margin="auto"
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Box display="flex" alignItems="center">
              {matchDownMD && (
                <CustomIconButton
                  disableRipple
                  aria-label="open drawer"
                  onClick={handleDrawerToggle}
                  color="secondary"
                  sx={{
                    transitionProperty: "all",
                    transitionDuration: "225ms",
                    TransitionTimingFunction: "cubic-bezier(0.4,0,0.6,1)",
                  }}
                >
                  {!open ? <MenuRounded /> : <MenuOpenRounded />}
                </CustomIconButton>
              )}
              <CustomLink to="/">
                <ProjectLogo style={{ width: matchDownMD ? 100 : 125 }} />
              </CustomLink>
            </Box>
            <Box display="flex" alignItems="center" gap={3}>
              <Box display={"flex"} gap={1} alignItems={"center"}>
                {!matchDownMD && (
                  <Collapse in={showSearch}>
                    <HeaderSearch />
                  </Collapse>
                )}
                <CustomIconButton
                  color="secondary"
                  onClick={
                    matchDownMD ? handleMobileSearchToggle : handleSearchToggle
                  }
                >
                  <SearchRounded
                    sx={{
                      width: "30px",
                      height: "30px",
                    }}
                  />
                </CustomIconButton>
                <CustomIconButton
                  color="secondary"
                  onClick={() => navigate("/cart")}
                >
                  <MuiBadge badgeContent={cart.items.length} color="primary">
                    <LocalMallRounded
                      sx={{
                        width: "30px",
                        height: "30px",
                      }}
                    />
                  </MuiBadge>
                </CustomIconButton>
                <Box
                  sx={{
                    display: { xs: "none", md: "flex" },
                    alignItems: "center",
                    gap: 1,
                  }}
                >
                  {userApiLoader ? (
                    <CircularProgress />
                  ) : (
                    <Fragment>
                      <HeaderAccountMenu />
                      {user.isDealer &&
                      user?.dealerDetails.progress === "approved" ? (
                        <MuiLoadingButton
                          onClick={handleDealerSwitchToggle}
                          sx={{ height: "fit-content" }}
                        >
                          {dealerSwitch ? (
                            <>Switch to retail</>
                          ) : (
                            <>Switch to dealer</>
                          )}
                        </MuiLoadingButton>
                      ) : (
                        // <CustomIOSSwitch
                        //   label={
                        //     dealerSwitch ? (
                        //       <>
                        //         Shopping as <br />
                        //         Dealer
                        //       </>
                        //     ) : (
                        //       <>
                        //         Shopping as <br />
                        //         Retail
                        //       </>
                        //     )
                        //   }
                        //   checked={dealerSwitch}
                        //   onChange={handleDealerSwitchToggle}
                        // />
                        <MuiLoadingButton
                          onClick={handleBecomeDealer}
                          sx={{ height: "fit-content" }}
                        >
                          Become a Dealer
                        </MuiLoadingButton>
                      )}
                    </Fragment>
                  )}
                </Box>
              </Box>
            </Box>
          </Box>
        </Toolbar>

        {matchDownMD ? (
          // <Box sx={{ position: "absolute", top: 80, width: "100%" }}>
          <Collapse in={showMobileSearch}>
            <Toolbar
              sx={{
                py: 2,
                justifyContent: "center",
                backgroundColor: "black",
                minHeight: "0 !important",
              }}
            >
              <HeaderSearch />
            </Toolbar>
          </Collapse>
        ) : (
          // </Box>
          <HeaderDesktopLinks />
        )}
      </AppBar>
    </HideOnScroll>
  );
}
