import { Box } from "@mui/material";
import Spline from "@splinetool/react-spline";
import React from "react";

export default function UniversePage() {
  return (
    <Box height="100vh" width="100%">
      <Spline scene="https://prod.spline.design/ILczSavfsFi-kdfr/scene.splinecode" />
    </Box>
  );
}
