import { useParams, useSearchParams } from "react-router-dom";
import axiosInstance from "utils/axios";
import {
  useDefaultSortState,
  useFilterDrawerState,
  useLoadingState,
  useProductsState,
  useResetProductsState,
  useSetFilterDrawerState,
} from "./states";
import { MouseEvent, useEffect, useState } from "react";
import useInfiniteScroll from "./useInfiniteScroll";
import { searchClient } from "utils/searchClient";

export default function useProductsFunctions() {
  const { first, second } = useParams();

  const [searchParams] = useSearchParams();

  const search = searchParams.get("search");

  const [open] = useFilterDrawerState();
  const setOpen = useSetFilterDrawerState();
  const [loading, setLoading] = useLoadingState();
  const [data, setData] = useProductsState();
  const dataReset = useResetProductsState();
  const [sort, setSort] = useDefaultSortState();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const menuOpen = Boolean(anchorEl);
  const [nextCursor, setNextCursor] = useState<string | null>(null);
  const [searchPage, setSearchPage] = useState<number>(0);
  const [researchData, setResearchData] = useState<boolean>(false);
  const [hasMore, setHasMore] = useState(true);

  const lastElementRef = useInfiniteScroll(loading, hasMore, () =>
    getSearchProducts(getQuery())
  );

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleFilterDrawerToggle = () => {
    setOpen(!open);
  };

  const getQuery = (): string => {
    const formatParam = (param: string | undefined) =>
      param ? param.replace(/-/g, " ") : "";

    if (search !== null && first === undefined && second === undefined) {
      return search;
    } else if (first) {
      let query = `/${formatParam(first)}`;
      if (second) {
        query = `/${formatParam(first)}/${formatParam(second)}`;
      }
      return query;
    }
    return " ";
  };
  const getSearchProducts = async (query: string) => {
    setLoading(true);

    let indexName = "products";

    if (sort.value === "hightolow") {
      indexName = "products_retail_price_dsc";
    } else if (sort.value === "lowtohigh") {
      indexName = "products_retail_price_asc";
    }

    try {
      const response = await searchClient.search([
        {
          indexName: indexName,
          query,
          params: {
            hitsPerPage: 20,
            page: searchPage,
          },
        },
      ]);

      const results = response.results[0];
      //@ts-ignore
      const hits = results?.hits || [];
      setData((prevData) => [...(prevData || []), ...hits]);
      setSearchPage((prev) => prev + 1);
      //@ts-ignore
      if (results?.nbPages <= searchPage) setHasMore(false);
    } catch (error) {
      console.error("Error fetching Algolia results:", error);
    } finally {
      setLoading(false);
      setResearchData(false);
    }
  };

  const getProducts = async () => {
    if (loading || !hasMore) return;
    setLoading(true);

    const query = getQuery();
    console.log(query);
    getSearchProducts(query);
  };

  const handleSort = async (option: { value: string; label: string }) => {
    setSort(option);
    research();
  };

  const research = async () => {
    console.log("research");
    setHasMore(true);
    setNextCursor(null);
    setSearchPage(0);
    dataReset();
    setResearchData(true);
  };

  useEffect(() => {
    if (researchData) getProducts();
  }, [researchData]);

  return {
    loading,
    open,
    data,
    sort,
    menuOpen,
    anchorEl,
    nextCursor,
    hasMore,
    search,
    first,
    second,
    setOpen,
    getSearchProducts,
    getProducts,
    handleFilterDrawerToggle,
    handleSort,
    handleClick,
    handleClose,
    lastElementRef,
    research,
  };
}
