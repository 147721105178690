import {
  AppBar,
  Box,
  Collapse,
  CustomIconButton,
  CustomInput,
  CustomLink,
  HideOnScroll,
  LocalMallRounded,
  MenuOpenRounded,
  MenuRounded,
  MuiBadge,
  MuiLoadingButton,
  Person2Rounded,
  ProjectLogo,
  SearchRounded,
  Toolbar,
  useMediaQuery,
} from "components/common";
import HeaderSearch from "components/header-search";
import HeaderDesktopLinks from "components/layouts/header-desktop-links";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { theme } from "theme";
import useFunctions from "../ts/useFunctions";

export default function Header() {
  const navigate = useNavigate();
  const {
    navbarOptions,
    open,
    handleDrawerToggle,
    setHeaderHeight,
    handleLoginDialogToggle,
    cart,
  } = useFunctions();
  const matchDownMD = useMediaQuery(theme.breakpoints.down("md"));
  const [showSearch, setShowSearch] = useState(false);
  const [showMobileSearch, setShowMobileSearch] = useState(false);

  const handleMobileSearchToggle = () => {
    setShowMobileSearch((prev) => !prev);
  };
  const handleSearchToggle = () => {
    setShowSearch((prev) => !prev);
  };

  const headerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (headerRef.current) {
      setHeaderHeight(headerRef.current.clientHeight);
    }
  }, [setHeaderHeight]);

  return (
    <HideOnScroll>
      <AppBar
        ref={headerRef}
        variant="outlined"
        sx={{
          zIndex: 10,
          width: "100%",
        }}
        elevation={0}
      >
        <Toolbar
          sx={{
            height: matchDownMD ? "80px" : "100px",
            justifyContent: "center",
            backgroundColor: "black",
            minHeight: "0 !important",
          }}
        >
          <Box
            width="100%"
            maxWidth={1200}
            margin="auto"
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Box display="flex" alignItems="center">
              {matchDownMD && (
                <CustomIconButton
                  disableRipple
                  aria-label="open drawer"
                  onClick={handleDrawerToggle}
                  color="secondary"
                  sx={{
                    transitionProperty: "all",
                    transitionDuration: "225ms",
                    TransitionTimingFunction: "cubic-bezier(0.4,0,0.6,1)",
                  }}
                >
                  {!open ? <MenuRounded /> : <MenuOpenRounded />}
                </CustomIconButton>
              )}
              <CustomLink to="/">
                <ProjectLogo style={{ width: matchDownMD ? 100 : 125 }} />
              </CustomLink>
            </Box>
            <Box display="flex" alignItems="center" gap={3}>
              {!matchDownMD && (
                <Collapse in={showSearch} unmountOnExit>
                  <HeaderSearch />
                  {/* <HeaderSearchInput /> */}
                </Collapse>
              )}
              <Box display={"flex"} gap={1} alignItems={"center"}>
                <CustomIconButton
                  color="secondary"
                  onClick={
                    matchDownMD ? handleMobileSearchToggle : handleSearchToggle
                  }
                >
                  <SearchRounded
                    sx={{
                      width: "30px",
                      height: "30px",
                    }}
                  />
                </CustomIconButton>
                <CustomIconButton
                  color="secondary"
                  onClick={() => handleLoginDialogToggle()}
                  sx={{ display: { xs: "none", md: "flex" } }}
                >
                  <Person2Rounded
                    sx={{
                      width: "30px",
                      height: "30px",
                    }}
                  />
                </CustomIconButton>
                <CustomIconButton
                  color="secondary"
                  onClick={() => navigate("/cart")}
                  sx={{ display: "flex" }}
                >
                  <MuiBadge badgeContent={cart.items.length} color="primary">
                    <LocalMallRounded
                      sx={{
                        width: "30px",
                        height: "30px",
                      }}
                    />
                  </MuiBadge>
                </CustomIconButton>
                <MuiLoadingButton
                  onClick={() => handleLoginDialogToggle(true)}
                  sx={{ display: { xs: "none", md: "flex" } }}
                >
                  Become Dealer
                </MuiLoadingButton>
              </Box>
            </Box>
          </Box>
        </Toolbar>

        {matchDownMD ? (
          // <Box sx={{ position: "absolute", top: 80, width: "100%" }}>
          <Collapse in={showMobileSearch}>
            <Toolbar
              sx={{
                py: 2,
                justifyContent: "center",
                backgroundColor: "black",
                minHeight: "0 !important",
              }}
            >
              <HeaderSearch />
            </Toolbar>
          </Collapse>
        ) : (
          // </Box>
          <HeaderDesktopLinks />
        )}
      </AppBar>
    </HideOnScroll>
  );
}
