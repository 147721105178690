import React from "react";
import { Box, Grid, Typography, ClickAwayListener } from "components/common";
import { Link } from "react-router-dom";

const HeaderDesktopLinksAccordionComponent = ({
  dropDown,
  closeDropDown,
}: any) => {
  const { label, categories, link_name } = dropDown;

  return (
    <ClickAwayListener onClickAway={closeDropDown}>
      <Box sx={{ userSelect: "none" }} onMouseLeave={closeDropDown}>
        <Box
          sx={{ padding: "10px 15px", textDecoration: "none" }}
          to={`/products/${link_name}`}
          onClick={closeDropDown}
          component={(props: any) => <Link {...props}>{props.children}</Link>}
        >
          <Typography
            sx={{
              color: "#000",
              fontSize: "20px",
              fontWeight: "500",
              padding: "8px 20px",
              width: "fit-content",
              cursor: "pointer",
              "&:hover": {
                backgroundColor: "#00E852",
                color: "#fff",
              },
            }}
          >
            {label?.toUpperCase()}
          </Typography>
        </Box>
        <Box sx={{ padding: 4, maxHeight: 500, overflowY: "auto" }}>
          <Grid
            container
            spacing={4}
            sx={{
              display: "grid",
              gridTemplateColumns: {
                xs: "1fr",
                md: "1fr 1fr",
                lg: "1fr 1fr 1fr",
              },
              gap: 3,
            }}
          >
            {categories.map((category: any) => (
              <Box key={category.title}>
                <Box
                  to={`/products/${link_name}/${category.id}`}
                  onClick={closeDropDown}
                  component={(props: any) => (
                    <Link {...props}>{props.children}</Link>
                  )}
                  sx={{
                    marginBottom: 1,
                    padding: "8px 16px",
                    cursor: "pointer",
                    transition: "all 0.3s ease",
                    fontWeight: "500",
                    color: "#333",
                    fontSize: "16px",
                    textTransform: "uppercase",
                    textDecoration: "none",
                    "&:hover": {
                      backgroundColor: "#00E852",
                      color: "#fff",
                      padding: "8px 20px",
                    },
                  }}
                >
                  {category.title}
                </Box>
              </Box>
            ))}
          </Grid>
        </Box>
      </Box>
    </ClickAwayListener>
  );
};

export default HeaderDesktopLinksAccordionComponent;
