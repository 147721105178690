import { Box, ScreenLoader, useMediaQuery } from "components/common";
import { Suspense } from "react";
import { Outlet } from "react-router-dom";
import { ReactComponent as Logo } from "assets/icons/Logo.svg";
import Header from "./header";
import useFunctions from "./ts/useFunctions";

import MainDrawer from "./drawer";
import { theme } from "theme";
import Footer from "components/layouts/footer";

export default function AuthedLayout() {
  const { loader, dealerSwitch, headerHeight } = useFunctions();
  const matchDownMD = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <>
      {dealerSwitch && (
        <Box
          position="fixed"
          top={0}
          width="100%"
          textAlign="center"
          bgcolor="black"
          fontSize="12px"
          color="white"
          zIndex={10000}
        >
          Shopping as dealer
        </Box>
      )}
      <Header />
      {matchDownMD && <MainDrawer />}
      <Box
        sx={{
          width: "100%",
          maxWidth: 2000,
          margin: "auto",
          mt: `${headerHeight}px`,
        }}
      >
        <Suspense fallback={<ScreenLoader />}>
          <Outlet />
        </Suspense>
        <Footer />
      </Box>
    </>
  );
}
