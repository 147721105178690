import { Box } from "components/common";
import Navigation from "./navigation";

const DrawerContent = () => (
  <Box
    mt="80px"
    height="calc(100% - 80px)"
    sx={{
      overflowY: "auto",
      overflowX: "hidden",
    }}
  >
    <Navigation />
  </Box>
);

export default DrawerContent;
