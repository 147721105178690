import {
  Autocomplete,
  MenuItem,
  Paper,
  TextField,
  useMediaQuery,
  Box,
  MenuList,
  ListItemButton,
  Typography,
} from "@mui/material";
import {
  CustomIconButton,
  SearchRounded,
  SubdirectoryArrowRightRounded,
} from "components/common";
import { useState, useMemo, useEffect, useRef } from "react";
import debounce from "lodash.debounce";
import { theme } from "theme";
import { searchClient } from "utils/searchClient";
import { Link, useNavigate } from "react-router-dom";

interface AlgoliaHit {
  objectID: string;
  title: string;
  category: string;
}

export default function HeaderSearch() {
  const navigate = useNavigate();
  const searchRef = useRef<HTMLInputElement>(null);
  const matchDownMD = useMediaQuery(theme.breakpoints.down("md"));
  const [loading, setLoading] = useState<boolean>(false);
  const [inputValue, setInputValue] = useState<string>("");
  const [options, setOptions] = useState<AlgoliaHit[]>([]);
  const [open, setOpen] = useState<boolean>(false);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.ctrlKey && event.key === "a") {
        event.preventDefault();
        searchRef.current?.focus();
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  const fetchOptions = async (query: string) => {
    if (query.length === 0) {
      setOptions([]);
      setOpen(false);
      return;
    }
    setLoading(true);

    try {
      const response = await searchClient.search([
        {
          indexName: "products",
          query,
          params: {
            hitsPerPage: 5,
            attributesToRetrieve: ["objectID", "title", "category"],
          },
        },
      ]);

      //@ts-ignore
      const hits = response.results[0]?.hits || [];
      setOptions(hits);
      setOpen(hits.length > 0);
    } catch (error) {
      console.error("Error fetching Algolia results:", error);
    } finally {
      setLoading(false);
    }
  };

  const debouncedFetchOptions = useMemo(() => debounce(fetchOptions, 1000), []);

  const handleInputChange = (query: string) => {
    setInputValue(query);
    debouncedFetchOptions(query);
  };

  useEffect(() => {
    return () => {
      debouncedFetchOptions.cancel();
    };
  }, [debouncedFetchOptions]);

  const handleOptionChange = (
    event: React.SyntheticEvent,
    value: string | AlgoliaHit | null,
    reason: string
  ) => {
    if (value && typeof value !== "string" && value.objectID) {
      navigate(`/product-details/${value.objectID}`);
    }
  };

  return (
    <Autocomplete
      freeSolo
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      options={options}
      loading={loading}
      getOptionLabel={(option) =>
        typeof option === "string" ? option : option.title
      }
      filterOptions={(options) => options}
      sx={{ width: { xs: "100%", md: 400 }, maxWidth: 400 }}
      onInputChange={(_, value) => handleInputChange(value)}
      noOptionsText="No Product Found"
      inputValue={inputValue}
      onChange={handleOptionChange}
      autoFocus={true}
      PaperComponent={(params) => <Paper {...params}>{params.children}</Paper>}
      ListboxComponent={(params) => (
        <MenuList {...params} sx={{ py: 1 }}>
          {options.length === 0 && !loading && inputValue.length > 0 && (
            <MenuItem
              disabled
              // sx={{ padding: 2, textAlign: "center", color: "gray" }}
            >
              No Product Found
            </MenuItem>
          )}
          {params.children}
          {options.length !== 0 && !loading && inputValue.length !== 0 && (
            <MenuItem
              color="primary"
              onClick={() => {
                navigate(`/products?search=${inputValue}`);
                setOpen(false);
                setInputValue("");
              }}
              sx={{
                mx: 1,
                justifyContent: "center",
              }}
            >
              Search "{inputValue}"
            </MenuItem>
          )}
        </MenuList>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          inputRef={searchRef}
          label="Search Products"
          margin="none"
          variant="outlined"
        />
      )}
      renderOption={(props, option) => (
        <MenuItem
          {...props}
          color="primary"
          // onClick={() => navigate(`/product-details/${option.objectID}`)}
          sx={{
            mx: 1,
            display: "flex",
            flexDirection: "column",
            alignItems: "start !important",
            justifyContent: "start",
            "&:hover": {
              "& svg": {
                fill: "#fff",
              },
              "& .category": {
                color: "#fff",
              },
              color: "#fff",
            },
          }}
        >
          <Typography
            color="inherit"
            sx={{
              width: "100%",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {option.title}
          </Typography>
          <Typography
            className="category"
            pl={2}
            fontSize="small"
            sx={{
              width: "calc(100% - 16px)",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            <SubdirectoryArrowRightRounded
              sx={{
                width: "12px",
                height: "12px",
              }}
            />
            {option.category}
          </Typography>
        </MenuItem>
      )}
    />
  );
}
